var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha Ingreso")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.datos.fecha_ingreso),expression:"datos.fecha_ingreso"}],staticClass:"form-control form-control-sm",class:{
                           'is-invalid': _vm.validator.fecha_ingreso.$error,
                           'is-valid': ! _vm.validator.fecha_ingreso.$error && _vm.validator.fecha_ingreso.$dirty
                       },attrs:{"type":"date"},domProps:{"value":(_vm.datos.fecha_ingreso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.datos, "fecha_ingreso", $event.target.value)}}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Hora Ingreso")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.datos.hora_ingreso),expression:"datos.hora_ingreso"}],staticClass:"form-control form-control-sm",class:{
                           'is-invalid': _vm.validator.hora_ingreso.$error,
                           'is-valid': ! _vm.validator.hora_ingreso.$error && _vm.validator.hora_ingreso.$dirty
                       },attrs:{"type":"time"},domProps:{"value":(_vm.datos.hora_ingreso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.datos, "hora_ingreso", $event.target.value)}}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha Egreso")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.datos.fecha_egreso),expression:"datos.fecha_egreso"}],staticClass:"form-control form-control-sm",class:{
                           'is-invalid': _vm.validator.fecha_egreso.$error,
                           'is-valid': ! _vm.validator.fecha_egreso.$error && _vm.validator.fecha_egreso.$dirty
                       },attrs:{"type":"date"},domProps:{"value":(_vm.datos.fecha_egreso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.datos, "fecha_egreso", $event.target.value)}}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Hora Egreso")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.datos.hora_egreso),expression:"datos.hora_egreso"}],staticClass:"form-control form-control-sm",class:{
                           'is-invalid': _vm.validator.hora_egreso.$error,
                           'is-valid': ! _vm.validator.hora_egreso.$error && _vm.validator.hora_egreso.$dirty
                       },attrs:{"type":"time"},domProps:{"value":(_vm.datos.hora_egreso)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.datos, "hora_egreso", $event.target.value)}}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Causa Externa")]),_c('causa-externa-field',{attrs:{"validator":_vm.validator.id_causa_externa},model:{value:(_vm.datos.causa_externa),callback:function ($$v) {_vm.$set(_vm.datos, "causa_externa", $$v)},expression:"datos.causa_externa"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Diagnostico Salida")]),_c('dx-field',{attrs:{"validator":_vm.validator.diagnostico_ppal},model:{value:(_vm.datos.diagnostico_ppal),callback:function ($$v) {_vm.$set(_vm.datos, "diagnostico_ppal", $$v)},expression:"datos.diagnostico_ppal"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Destino de Salida")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.datos.destino_salida),expression:"datos.destino_salida"}],staticClass:"form-control form-control-sm",class:{
                           'is-invalid': _vm.validator.destino_salida.$error,
                           'is-valid': ! _vm.validator.destino_salida.$error && _vm.validator.destino_salida.$dirty
                       },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.datos, "destino_salida", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.destinos),function(destino,index){return _c('option',{key:index,domProps:{"value":destino.codigo}},[_vm._v(" "+_vm._s(destino.descripcion)+" ")])}),0)])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Estado de Salida")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.datos.estado_salida),expression:"datos.estado_salida"}],staticClass:"form-control form-control-sm",class:{
                           'is-invalid': _vm.validator.estado_salida.$error,
                           'is-valid': ! _vm.validator.estado_salida.$error && _vm.validator.estado_salida.$dirty
                       },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.datos, "estado_salida", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.estados_salida),function(estado,index){return _c('option',{key:index,domProps:{"value":estado.codigo}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])}),0)])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Descripcion de la Urgencia")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.datos.descripcion),expression:"datos.descripcion"}],staticClass:"form-control form-control-sm",class:{
                           'is-invalid': _vm.validator.descripcion.$error,
                           'is-valid': ! _vm.validator.descripcion.$error && _vm.validator.descripcion.$dirty
                       },domProps:{"value":(_vm.datos.descripcion)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.datos, "descripcion", $event.target.value)}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }